// @flow

import type { Node } from 'react'
import React from 'react'
import SeoPageTemplate from '../scenes/seo-pages'

function SeoVacationInFlorida(): Node {
  return <SeoPageTemplate title="Vacation in Florida" />
}

export default SeoVacationInFlorida
